import axios from 'axios'

const makeReqest = async (query) => {
  return axios.post(`${process.env.VUE_APP_HICDEX_API_URL}/v1/graphql`, { query: query })
}

export default {

  async getActiveEnglishAuctions () {
    const query = `query ActiveEnglishAuctions {
  hic_et_nunc_fa2 {
    english_auctions(where: {fa2: {contract: {_eq: "KT1LHHLso8zQWQWg1HUukajdxxbkGfNoHjh6"}}, status: {_eq: "active"}}, limit: 10000) {
      id
      bids(limit: 1, order_by: {amount: desc}) {
        amount
      }
      objkt_id
      reserve
      end_time
      status
      hash
      price_increment
      extension_time
    }
  }
}`;
    return makeReqest(query).then(res => {
      return res.data.data.hic_et_nunc_fa2[7].english_auctions;
    });
  },

  async getActiveDutchAuctions () {
    const query = `query ActiveDutchAuctions {
  hic_et_nunc_fa2 {
    dutch_auctions(where: {fa2: {contract: {_eq: "KT1LHHLso8zQWQWg1HUukajdxxbkGfNoHjh6"}}, status: {_eq: "active"}}, limit: 10000) {
      id
      buy_price
      end_price
      end_time
      hash
      objkt_id
      start_price
      status
    }
  }
}`;
    return makeReqest(query).then(res => {
      return res.data.data.hic_et_nunc_fa2[7].dutch_auctions;
    });
  },

  async getActiveAsks () {
    const query = `query ActiveAsks {
  hic_et_nunc_fa2 {
    asks(where: {fa2: {contract: {_eq: "KT1LHHLso8zQWQWg1HUukajdxxbkGfNoHjh6"}}, status: {_eq: "active"}}, limit: 10000, order_by: {price: asc}) {
      status
      price
      objkt_id
      timestamp
    }
  }
}`;
    return makeReqest(query).then(res => {
      return res.data.data.hic_et_nunc_fa2[7].asks;
    });
  }

}
