<template>
  <el-container id="app">
    <el-header>
      <h1>FUKKRS.GALLERY</h1>
      <span>Look at all these Snazzy Fukkrs</span>
    </el-header>
    <el-divider></el-divider>
    <router-view :key="$route.name + ($route.params.id || '')"></router-view>
    <el-footer>
      <el-link :underline="false" href="https://twitter.com/mycodecrafting" target="_blank"><i class="fab fa-twitter" style="color: #1DA1F2"></i></el-link> Built by Codecrafting Labs. Made with <i class="fas fa-heart" style="color: #F64947"></i>
    </el-footer>
  </el-container>
</template>

<script>
export default {
  name: 'app',
  components: {
  }
}
</script>

<style>
html,
body {
  margin: 0;
  height: 100%;
  background: #FFF3EA;
}

body {
  font-family: 'Patrick Hand SC', sans-serif;
}

#app {
  max-width: 1280px;
  margin: 0 auto;
}

#home, #fukkr {
    padding: 0 10px;
}

.el-header {
    position: relative;
    height: auto !important;
}
.el-header h1, #fukkr h2 {
    font-family: 'Passion One', cursive;
    font-size: 4.5em;
    margin-top: 12px;
    margin-bottom: 0px;
    text-transform: uppercase;
    text-shadow: 1px -1px 0 #ffffff80,-3px 3px 0 #fbe3b44d,0 0 10px #a5e9c3;
}
#fukkr h2 {
    font-size: 2.5em;
    margin-bottom: 24px;
}
@media only screen and (max-width:767px) {
    .el-header h1 {
        font-size: 3.5em;
    }
}

.fa-icon-right {
    margin-left: 6px;
}

.el-header span {
    font-family: 'Patrick Hand SC', sans-serif;
    font-size: 24px;
    text-transform: uppercase;
    text-shadow: 1px -1px 0 #ffffff80,-3px 3px 0 #fbe3b44d,0 0 10px #a5e9c3;
}

.menu {
    margin-bottom: 20px;
}

.menu .el-collapse-item__header {
    font-size: 18px;
}
.menu .el-checkbox__label {
    display: inline-block;
    width: 100%;
}

.el-tag {
    font-family: Roboto, sans-serif;
    font-size: 10px;
}

.active-filters .el-tag {
    margin-left: 8px;
    border-radius: 18px;
}

.menu .el-checkbox:last-of-type {
    margin-right: 30px !important;
}

.el-collapse-item:last-child .el-collapse-item__wrap,
.el-collapse-item:last-child .el-collapse-item__header {
    border-bottom: none !important;
}

.el-checkbox {
    display: block !important;
}

.el-footer {
    text-align: center;
    margin-top: 60px;
    padding: 20px 0;
}

.el-empty {
    padding-top: 80px !important;
}
.el-empty__description {
    margin-top: 40px !important;
}
.el-empty__description p {
    font-size: 24px !important;
}
.el-empty__image {
    opacity: 0.5;
}

.el-breadcrumb {
    margin-bottom: 24px !important;
    font-size: 18px !important;
}

#fukkr .el-descriptions__body {
    background: rgba(255, 255, 255, 0.6) !important;
    font-size: 1.35em;
    padding: 10px;
}
#fukkr .el-descriptions-item__label {
    width: 33%;
    text-align: right;
    font-weight: bold;
    font-family: 'Passion One', cursive;
    font-size: 1em;
    text-transform: uppercase;
    color: #000;
    line-height: 1.6em;
}
#fukkr .el-descriptions-item__content {
    width: 65%;
}
#fukkr .trait-combos .el-card .el-divider {
    margin-top: 0 !important;
    margin-bottom: 12px !important;
}
#fukkr .trait-combos .el-card  .el-descriptions__body {
    font-size: 1.25em;
}
#fukkr .trait-combos .el-card .el-descriptions-item__label {
    width: 40%;
}
#fukkr .trait-combos .el-card .el-descriptions-item__content {
    width: 58%;
}

.price {
    color: #666;
}

</style>
